import {BrowserRouter as Router, Navigate, Route, Routes, useLocation} from 'react-router-dom';
import Home from './pages/Home';
import Price from './pages/PricesPage';
import Aboutus from './pages/AboutUs';
import Contact from './pages/ContactUs';
import Series from './pages/Series';
import Courses from './pages/Courses';
import IntensityBased from './pages/IntensityBased';
import TimeBased from './pages/TimeBased';
import Howto from './pages/HowTo';
import NotFound from './pages/NotFound';
import UserLogin from './pages/UserLogin';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from "./components/ScrollToTop";
import './App.css';
import EmailVari from './pages/EmailVari';
import NewAccount from './pages/NewAccount';
import SelectPackage from './pages/SelectPackage';
import Profile from './pages/Profile';
import Checkout from './pages/Checkout';
import Legal from './pages/Legal';
import {useEffect, useState} from "react";
import {getEmail, getNewUser, getUser} from "./auth/Auth";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SetupPayment from "./components/AddNewPaymentMethod";

function App() {
    const [user, setUser] = useState(undefined),
        [email, setEmail] = useState(undefined),
        [newUser, setNewUser] = useState(undefined);

    const location = useLocation();

    useEffect(() => {
        getUser(false).then((data) => {
            setUser(data === undefined ? null : data);
        }).catch(() => {
            setUser(null);
        });
        getEmail(false).then((data) => {
            setEmail(data === undefined ? null : data);
        }).catch(() => {
            setEmail(null);
        });
        getNewUser(false).then((data) => {
            setNewUser(data === undefined ? null : data);
        }).catch(() => {
            setNewUser(null);
        });
    }, []);

    // Function to determine if the current route should hide the header and footer
    const shouldHideHeaderFooter = () => {
        const noHeaderFooterRoutes = ["login", "forgot-password", "reset-password", "signup", "email-verification", "select-package"];
        return noHeaderFooterRoutes.includes(location.pathname.split("/")[1]);
    };

    if (user !== undefined && email !== undefined && newUser !== undefined) {
        return (
            <div>
                {!shouldHideHeaderFooter() && <Header/>}
                <div>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/price" element={<Price/>}/>
                        <Route path="/about-us" element={<Aboutus/>}/>
                        <Route path="/contact-us" element={<Contact/>}/>
                        <Route path="/series" element={<Series/>}/>
                        <Route path="/courses" element={<Courses/>}/>
                        <Route path="/intensity-based" element={<IntensityBased/>}/>
                        <Route path="/time-based" element={<TimeBased/>}/>
                        <Route path="/how-to" element={<Howto/>}/>
                        <Route path="/login" element={<UserLogin/>}/>
                        <Route path="/profile" element={user ? user.pricing_packages.length > 0 ? <Profile/> :
                            <Navigate to={'/select-package'}/> : <Navigate to={'/login'}/>}/>

{/* <Route path="/profile" element={user ? <Profile /> : <Navigate to="/login" />} /> */}

                        <Route path="/forgot-password" element={<ForgotPassword/>}/>
                        <Route path="/reset-password/:token" element={<ResetPassword/>}/>
                        <Route path="/email-verification" element={<EmailVari/>}/>
                        <Route path="/Checkout"
                               element={user || newUser ? <Checkout isTransaction={false}/> :
                                   <Navigate to={'/signup'}/>}/>
                        <Route path="/transaction/complete"
                               element={user ? <Checkout isTransaction={true}/> : <Navigate to={'/login'}/>}/>
                        <Route path="/signup" element={email ? <NewAccount/> : <Navigate to={'/email-verification'}/>}/>
                        <Route path="/select-package"
                               element={newUser || user ? <SelectPackage/> : <Navigate to={'/signup'}/>}/>
                        <Route path="/legal" element={<Legal/>}/>
                        <Route path="/setup/complete"
                               element={user ? <SetupPayment isTransaction={true}/> : <Navigate to={'/login'}/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover/>
                </div>
                {!shouldHideHeaderFooter() && <Footer/>}
            </div>
        );
    }
}

function MainApp() {
    return (
        <Router>
            <ScrollToTop>
                <App/>
            </ScrollToTop>
        </Router>
    );
}

export default MainApp;
